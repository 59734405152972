import { Col, Row } from "antd"
import { FC, useState } from "react"
import { useBreakpoint } from "../../../hooks/useBreakPoint"
import { Burger } from "../../burger-menu/BurgerButton"
import { BurgerMenu } from "../../burger-menu/Menu"
import { CustomButton } from "../../button/CustomButton"
import { Logo } from "../../logo"
import { NavMenu } from "../../menu/NavMenu"
import styles from "./Header.module.scss"

export const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = NavMenu }) => {
  const [isMenuOpen, setMenuOpened] = useState(false)
  
  const onOpen = () => {
    setMenuOpened(true)
  }

  const onClose = () => {
    setMenuOpened(false)
  }

  const { lg, xs } = useBreakpoint()

  return (
    <Row className={styles.header}>
      <BurgerMenu onClose={onClose} isOpen={isMenuOpen} />

      {!lg && (
        <Col
          sm={{ order: 1 }}
          xs={{ order: 2 }}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Burger onClick={onOpen} />
        </Col>
      )}

      <Col lg={{ order: 1 }} md={{ order: 2 }} xs={{ order: 1 }} className={styles.logo}>
        <Logo theme={"dark"} />
      </Col>

      {lg && (
        <Col lg={{ order: 2 }}>
          <HorizontalMenu />
        </Col>
      )}

      {!lg && !xs && (
        <Col sm={{ order: 3 }} className={styles["button-wrapper"]}>
          <CustomButton label={"Contact us"} type={"dark"} />
        </Col>
      )}
    </Row>
  )
}
