import { FC } from "react";
import { NavItem } from "../NavItem";
import { NavigationItem } from "../../../types/navigation";
import styles from './NavMenu.module.scss';
import { CustomButton } from "../../button";
import { Col } from "antd";

const items: NavigationItem[] = [
  { label: 'Services', to: 'services'},
  { label: 'Resources', to: 'resources' },
  { label: 'Open roles', to: 'open-roles' },
];

const NavMenu: FC = () => (
  <ul className={styles['nav-menu']}>
    {items.map(({ label, to }) => (
      <NavItem key={label} label={label} to={to} />
    ))}
    <Col className={styles['button-wrapper']}>
      <CustomButton label={'Contact Us'} type={'dark'} />
    </Col>
  </ul>
)

export { NavMenu }
