import { FC } from "react";
import styles from './BurgerButton.module.scss'
import classnames from "classnames";
import { Button } from "antd";

type Props = {
  onClick: () => void
}

export const Burger: FC<Props> = ({ onClick }) => (
  <div className={styles['container']}>
    <Button onClick={onClick} className={styles['button']}>
      <div className={classnames(
        styles['slash'],
        styles['slash-1']
      )} />

      <div className={classnames(
        styles['slash'],
        styles['slash-2']
      )} />

      <div className={classnames(
        styles['slash'],
        styles['slash-3']
      )} />
    </Button>
  </div>
)