import { Space } from "antd"
import { FC } from "react"

import { ReactComponent as LogoImageLight } from "../../assets/icons/logo-light.svg";
import { ReactComponent as LogoImageDark } from "../../assets/icons/logo-dark.svg";

type Props = {
  theme: 'dark' | 'light'
}

const Logo: FC<Props> = ({ theme }) => {
  return (
    <Space direction={"horizontal"} align={"center"}>
      {theme === 'dark' ? (
        <LogoImageDark 
          onClick={() => window.location.assign("/")}
        />
        ) : (
          <LogoImageLight 
          onClick={() => window.location.assign("/")} 
        />
        )
      }
    </Space>
  )
}

export { Logo }
