import { Col, Layout, Row, Typography } from "antd"
import { FC } from "react"
import { Logo } from "../../logo"
import styles from './Footer.module.scss'
import { Link } from "react-router-dom"
import { ReactComponent as LinkedIn } from '../../../assets/icons/linkedin.svg';

const Footer: FC = () => {

  return (
    <Layout.Footer  className={styles['footer-container']}>
      <Row className={styles.footer}>
        <Col className={styles['logo-section']}>
          <Logo theme={'light'} />
          <Typography.Text className={styles.title}>
            Greater Talent
          </Typography.Text>
        </Col>

        <Col className={styles.section}>
          <Typography.Text className={styles.subtitle}>
            Company
          </Typography.Text>

          <Typography.Text className={styles.navigation}>
            <Link 
              to={'/contact-us'}
              className={styles.navigation}
            >
              Contact us
            </Link>
          </Typography.Text>
        </Col>

        <Col className={styles.section}>
          <Typography.Text className={styles.subtitle}>
            Get supported
          </Typography.Text>

          <Typography.Text className={styles.navigation}>
            <Link 
              to={'/services'}
              className={styles.navigation}
            >
              Services
            </Link>
          </Typography.Text>

          <Typography.Text className={styles.navigation}>
            <Link 
              to={'/resources'}
              className={styles.navigation}
            >
              Blog
            </Link>
          </Typography.Text>
        </Col>

        <Col className={styles.section}>
          <Typography.Text className={styles.subtitle}>
            Stay in touch
          </Typography.Text>

          <Typography.Text className={styles.navigation}>
            <a 
              href={"mailto:info@greatertalentgroup.com"}
              style={{ color: 'white' }}
            >
              info@greatertalentgroup.com
            </a>
          </Typography.Text>

          <Typography.Text className={styles.navigation}>
            <a 
              href={"http://www.greatertalentgroup.com"} 
              target={"_blank"} 
              rel={"noopener noreferrer"}
              style={{ color: 'white' }}
            >
              www.greatertalentgroup.com
            </a>
          </Typography.Text>
            
            <a href={'https://www.linkedin.com/company/greater-talent-group/'}>
              <LinkedIn/>
            </a>
        </Col>
      </Row>
    </Layout.Footer>
  )
}

export { Footer }
