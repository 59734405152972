import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BlogArticle",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiServicesSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Email",
      "Home",
      "Layout",
      "ReactIconsIconlibrary",
      "ServicesPage",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vacancy"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "BlogArticle",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiServicesSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Email",
      "Home",
      "Layout",
      "ReactIconsIconlibrary",
      "ServicesPage",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Vacancy"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const BlogArticleFragmentDoc = gql`
    fragment BlogArticle on BlogArticle {
  title
  author
  article
  createdAt
  media {
    data {
      ...File
    }
  }
}
    `;
export const EmailFragmentDoc = gql`
    fragment Email on Email {
  replyTo
  name
  service
  message
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
  media2 {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const ServicesSectionFragmentDoc = gql`
    fragment ServicesSection on ComponentUiServicesSection {
  id
  headline {
    ...Headline
  }
  sectionCard {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const VacancyFragmentDoc = gql`
    fragment Vacancy on Vacancy {
  vacancyTitle
  url
  location
  image {
    data {
      ...File
    }
  }
}
    `;
export const CreateEmailDocument = gql`
    mutation createEmail($data: EmailInput!) {
  createEmail(data: $data) {
    data {
      attributes {
        replyTo
        name
        service
        message
      }
    }
  }
}
    `;
export type CreateEmailMutationFn = Apollo.MutationFunction<CreateEmailMutation, CreateEmailMutationVariables>;
export function useCreateEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailMutation, CreateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailMutation, CreateEmailMutationVariables>(CreateEmailDocument, options);
      }
export type CreateEmailMutationHookResult = ReturnType<typeof useCreateEmailMutation>;
export type CreateEmailMutationResult = Apollo.MutationResult<CreateEmailMutation>;
export const BlogArticlesDocument = gql`
    query blogArticles($pagination: PaginationArg!) {
  blogArticles(pagination: $pagination) {
    data {
      id
      attributes {
        ...BlogArticle
      }
    }
  }
}
    ${BlogArticleFragmentDoc}
${FileFragmentDoc}`;
export function useBlogArticlesQuery(baseOptions: Apollo.QueryHookOptions<BlogArticlesQuery, BlogArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlogArticlesQuery, BlogArticlesQueryVariables>(BlogArticlesDocument, options);
      }
export function useBlogArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlogArticlesQuery, BlogArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlogArticlesQuery, BlogArticlesQueryVariables>(BlogArticlesDocument, options);
        }
export type BlogArticlesQueryHookResult = ReturnType<typeof useBlogArticlesQuery>;
export type BlogArticlesLazyQueryHookResult = ReturnType<typeof useBlogArticlesLazyQuery>;
export type BlogArticlesQueryResult = Apollo.QueryResult<BlogArticlesQuery, BlogArticlesQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
        contactUs {
          ...Section
        }
        services {
          ...ServicesSection
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${ServicesSectionFragmentDoc}
${HeadlineFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ServicesPageDocument = gql`
    query servicesPage {
  servicesPage {
    data {
      attributes {
        servicesHero {
          ...ServicesSection
        }
        recruitmentSolutions {
          ...ServicesSection
        }
        consultingSection {
          ...ServicesSection
        }
        awards {
          ...ServicesSection
        }
      }
    }
  }
}
    ${ServicesSectionFragmentDoc}
${HeadlineFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useServicesPageQuery(baseOptions?: Apollo.QueryHookOptions<ServicesPageQuery, ServicesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesPageQuery, ServicesPageQueryVariables>(ServicesPageDocument, options);
      }
export function useServicesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesPageQuery, ServicesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesPageQuery, ServicesPageQueryVariables>(ServicesPageDocument, options);
        }
export type ServicesPageQueryHookResult = ReturnType<typeof useServicesPageQuery>;
export type ServicesPageLazyQueryHookResult = ReturnType<typeof useServicesPageLazyQuery>;
export type ServicesPageQueryResult = Apollo.QueryResult<ServicesPageQuery, ServicesPageQueryVariables>;
export const VacanciesDocument = gql`
    query vacancies {
  vacancies {
    data {
      id
      attributes {
        ...Vacancy
      }
    }
  }
}
    ${VacancyFragmentDoc}
${FileFragmentDoc}`;
export function useVacanciesQuery(baseOptions?: Apollo.QueryHookOptions<VacanciesQuery, VacanciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VacanciesQuery, VacanciesQueryVariables>(VacanciesDocument, options);
      }
export function useVacanciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VacanciesQuery, VacanciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VacanciesQuery, VacanciesQueryVariables>(VacanciesDocument, options);
        }
export type VacanciesQueryHookResult = ReturnType<typeof useVacanciesQuery>;
export type VacanciesLazyQueryHookResult = ReturnType<typeof useVacanciesLazyQuery>;
export type VacanciesQueryResult = Apollo.QueryResult<VacanciesQuery, VacanciesQueryVariables>;