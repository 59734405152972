import { Col, Divider, Drawer, Row } from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import styles from './BurgerMenu.module.scss';
import { ReactComponent as HomeIcon } from 
'../../../assets/icons/burger-menu/home_menu_icon.svg'
import { ReactComponent as ResourcesIcon } from 
'../../../assets/icons/burger-menu/resources_menu_icon.svg'
import { ReactComponent as ServicesIcon } from 
'../../../assets/icons/burger-menu/services_menu_icon.svg'
import { ReactComponent as UserIcon } from 
'../../../assets/icons/burger-menu/user_menu_icon.svg'
import { CloseOutlined } from "@ant-design/icons";
import { Logo } from "../../../components/logo";
import { useBreakpoint } from "../../../hooks/useBreakPoint";

type Props = {
  isOpen: boolean
  onClose: () => void
}

const menuItems = [
  { to: '/', label: 'Home', icon: <HomeIcon /> },
  { to: '/services', label: 'Services', icon: <ResourcesIcon /> },
  { to: '/resources', label: 'Resources', icon: <ServicesIcon />},
  { to: '/open-roles', label: 'Open roles', icon: <UserIcon />}
]

export const BurgerMenu: FC<Props> = ({ isOpen, onClose }) =>  {
  const { xs } = useBreakpoint();
  return (
    <Drawer
      width={xs ? '85%' : '50%'}
      onClose={onClose}
      placement={"left"}
      closeIcon={null}
      title={
      <Row style={{ 
          display: "flex", 
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Col>
          <Logo theme={'light'} />
        </Col>

        <Col>
          <CloseOutlined style={{ color: "white" }} onClick={onClose}/>
        </Col>
      </Row>}
      className={styles['menu-drawer']}
      open={isOpen}
      headerStyle={{ 
        border: 'none',
        display: "flex", 
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        paddingTop: '42px'
      }}
    >
      <Divider style={{ backgroundColor: '#FFFFFF1F' }} />
      <ul className={styles['menu-list']}>
        {menuItems.map(({ label, to, icon }) => (
          <Link 
            to={to}
            key={label} 
            onClick={onClose}
            className={styles['menu-item']}
          >
            {icon}
            {label}
          </Link>
        ))}
      </ul>
    </Drawer>
  )
}
