import { FC } from "react"
import { NavLink } from "react-router-dom"
import { NavigationItem } from "../../../types/navigation"
import styles from './NavItem.module.scss';

export const NavItem: FC<NavigationItem> = ({ label, to }) => {
  
  return (
      <li>
        <NavLink to={to} 
          className={({ isActive }) => 
          isActive ? styles.active : styles['nav-item']}
        >
          {label}
        </NavLink>
      </li>
  )
}
