import { FC } from 'react';
import { Button } from 'antd';
import styles from './CustomButton.module.scss';
import classnames from 'classnames';

type Props = {
  label: string,
  type: 'light' | 'dark',
}

export const CustomButton: FC<Props> = ({ label, type }) => {
  return (
    <Button
      href={"/contact-us"}
      className={classnames(
        styles.button,
        {
          [styles.light]: type === 'light',
          [styles.dark]: type === 'dark'
        }
      )}
    >
      {label}
    </Button>
  );
}
