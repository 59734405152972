import { FC, lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";
import DefaultLayout, { Loader } from "../components/layout";

const Home = lazy<FC>(() => import("./home"));
const Resources = lazy<FC>(() => import("./resources"));
const Article = lazy<FC>(() => import("./article"));
const Services = lazy<FC>(() => import("./services"));
const OpenRoles = lazy<FC>(() => import("./open-roles"));
const ContactUs = lazy<FC>(() => import("./contact-us"));

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: Resources,
        path: "/resources",
      },
      {
        Component: Article,
        path: "/resources/:id",
      },
      {
        Component: Services,
        path: "/services",
      },
      {
        Component: OpenRoles,
        path: "/open-roles",
      },
      {
        Component: ContactUs,
        path: "/contact-us",
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

export default routes
