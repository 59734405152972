import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Footer, Header } from ".."
import { NavMenu } from "../../menu/NavMenu"

export const Layout: FC = () => (
  <BaseLayout>
    <BaseLayout style={{ minHeight: '100vh' }}>
      <Header renderMenu={NavMenu} />
      <Suspense>
        <Outlet />
      </Suspense>
      <Footer />
    </BaseLayout>
  </BaseLayout>
)
